.App-description-desktop {
    display: flex;
    flex-direction: column;
    max-width: 70vw;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 2vmin);
    font-weight: bold;
    color: #8B5CF6;
}

.App-description-contents {
    max-width: 70vw;
    text-align: left;
    font-size: calc(9px + 2vmin);
    font-weight: bold;
    color: black;
}

.App-download-ios-img {
    height: 53px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.App-desktop-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: start;
    align-content: center;
}

.App-download {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-content: center;
}