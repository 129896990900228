.App {
    text-align: center;
    background-color: #D5C6F8;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    overflow: hidden;
}

.App-logo {
    pointer-events: none;
    width: 195px;
    margin-bottom: 40px;
}

.App-header {
    display: flex;
    flex-direction: column;
    max-width: 70vw;
    align-items: center;
    justify-content: center;
    font-size: calc(15px + 2vmin);
    font-weight: bold;
    color: #8B5CF6;
}

.App-download-img {
    width: 200px;
}

.App-download-ios {
    display: inline-block;
    overflow: hidden;
    border-radius: 13px;
    width: 250px;
    height: 83px;
}

.App-download-ios-img {
    border-radius: 13px; width: 250px; height: 83px;
}

.App-link {
    color: #61dafb;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
